<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">供片系统设备方</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/' }">内部管理</el-breadcrumb-item>
                <el-breadcrumb-item><a>设备回传日志</a></el-breadcrumb-item>
            </el-breadcrumb>
            <div class="table_top">

                <div style="float:left;">
                    <el-input placeholder="请输入内容" class="input-with-select" >

                        <el-button slot="append" style="background: #409eff;color: #fff;border-radius: 0 4px 4px 0;" >搜索</el-button>
                    </el-input>
                </div>
            </div>
            <!-- <div style="float:right;">
                  <el-button type="primary">新建策略</el-button>
                <el-button type="primary">导入策略</el-button>

                <el-button style="float:right;" onclick="bButton('table_ver_cl.html')">返回</el-button>
            </div> -->

            <!-- <table>
                <tbody>
                <tr>
                    <th style="font-weight: 600;">设备名称</th>
                    <th style="font-weight: 600;">回传日期</th>
                    <th style="font-weight: 600;">回传内容</th>
                    <th style="font-weight: 600;">设备状态</th>
                </tr>
                <tr>
                    <th>设备名称</th>
                    <th>2021-12-14</th>
                    <th>——</th>
                    <th>正常</th>
                </tr>
                </tbody>
            </table> -->
			<template>
				<el-table :data="tableData" stripe border size="small"
						 header-cell-class-name="table_header"
						cell-class-name="table_cell" style="width: 100%">
					<el-table-column
							prop="model"
							label="设备名称"
							min-width="100"
					>
					</el-table-column>
					<el-table-column
							prop="num"
							label="回传日期">
					</el-table-column>
					<el-table-column
							prop="content"
							label="回传内容">
					</el-table-column>
					<el-table-column
							prop="status2"
							label="设备状态">
					</el-table-column>

				</el-table>
			</template>
						
            <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
        </el-main>
    </el-container>
</template>

<script>
    export default {
        name: "Menu3_Uploadlog",
		data(){
			return{
				tableData:[{
					model: '设备名称',
					num: '2021-12-14',
					content: '————',
					status2: '0',
				}],
				ruleForm:{
					name: '',
					name1: ''
				},
				addModelVisible: false
			}
		}
    }
</script>

<style scoped>

</style>